import "./nutribot.css"

function Nutribot() {
     return (
          <div className="home">
               <div className="intro2">
               
                    <div className="teaser">
                         Three simple steps to success
                         <br></br>
                         
                         <img src="/img/nutribot2.png" className="cover2" alt="nutribot"></img>
                         <br></br>
                         Get fresh Water, Get some Nutrients, Feed'em 
                         <br></br> 
                         <div className="teaser2" >
                              The full Nutribot system, feeds whatever you want  
                                   
                         </div>

                         




                         <div>
                         <p className="paragraph">Now all your Nutribot funktions are included in your Feeder. </p>
                              <br></br>
                            
                         <br></br>
                         <p className="paragraph">
                         Like this you can power your pumps with more load. The Gardena 12V Holidaywatering-System is
                         the perfect pump for the Nutribot.
                         <br></br>
                         
                         To apply the software to the peristaltik pumps you only have to multiply the amount of milliliters you need to run your pump
                         by 0.33. This will give you a corresponding seconds. Just enter straight numbers without any comma.
                         <br></br>
                         To make things clear: we multiply
                          the amount of milliliters we need with
                         0.33 what is the time to pump 1 milliliter.
                         </p>
                         <br></br>
                         <p className="paragraph">
                         For more advanced users: You can also delete
                          the coef variable in the equation of the time in your feeder.py file on your Desktop.
                          </p>
                          
                          
                          
                          
                        
                         </div>
                         
                    </div>
               </div>
               
          </div>
     )
}

export default Nutribot
